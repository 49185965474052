import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useHistory } from "react-router-dom";

const useLoadAppInsights = () => {
  const history = useHistory();
  var reactPlugin = new ReactPlugin();
  var appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_APP_INSIGHTS_CNN_STRING,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      addRequestContext: () => ({
        Application: `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_ENVIRONMENT}`,
      }),
      extensionConfig: {
        [reactPlugin.identifier]: { history: history },
      },
    },
  });
  appInsights.loadAppInsights();
};

export { useLoadAppInsights };
