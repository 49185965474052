import { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FooterComponent from "./components/Footer";
import { useGetCopy } from "./api/queries/copy";
import { Navigation, SimplifiedNavigation } from "./components/Navigation";
import { useGetUser } from "./contexts/userContext";
import { composeResource } from "./utils/composeResource";
import { useGetCart } from "./contexts/cartContext";
import { PageLoader } from "./components/PageLoader";

const Basket = lazy(() => import("./routes/Basket"));
const Campaign = lazy(() => import("./routes/Campaign"));
const Categories = lazy(() => import("./routes/Categories"));
const Category = lazy(() => import("./routes/Category"));
const Checkout = lazy(() => import("./routes/Checkout"));
const Home = lazy(() => import("./routes/Home"));
const MyDetails = lazy(() => import("./routes/MyDetails"));
const OrderStatus = lazy(() => import("./routes/OrderStatus"));
const ReloadableCards = lazy(() => import("./routes/ReloadableCards"));
const Security = lazy(() => import("./routes/Security"));
const Notifications = lazy(() => import("./routes/Notifications"));
const Favourites = lazy(() => import("./routes/Favourites"));
const Offer = lazy(() => import("./routes/Offer"));
const MyOrderHistory = lazy(() => import("./routes/MyOrderHistory"));

export function Routes() {
  const { loading, data: userData } = useGetUser();

  const Nav = () => {
    const { data } = useGetCopy({ key: "global.header" });

    const { data: cartData } = useGetCart();

    const { getText, getLinkSet, getOfferSet, getCategorySet } =
      composeResource(data, userData.catalogue?.ids);

    useEffect(() => {
      if (!userData.member) return;

      window.localStorage.setItem(
        "userCashBackTermsVersion",
        userData.member.cashBackTermsVersion || ""
      );
    }, []);

    if (loading || !userData.member) {
      return null;
    }

    return (
      <Navigation
        logo={userData.member.companyLogo}
        logoAlt={""}
        sitename={userData.member.memberCompanyLocation.company.companyName}
        secondaryLogo={userData.member.companyBrandLogo}
        secondaryLogoAlt={""}
        hasAccessToCashBack={userData.member.hasAccessToCashBack}
        basketCount={
          cartData.length
            ? cartData.length > 9
              ? "9+"
              : cartData.length.toString()
            : undefined
        }
        accountPanel={{
          copy: {
            logout: getText("account.logout"),
            title: getText("account.title"),
          },
          links:
            getLinkSet("header.accountLinks")?.map((i) => ({
              title: i.label,
              caption: i.caption,
              url: i.url,
              alt: i.ariaLabel,
            })) || [],
        }}
        copy={{
          home: getText("toolbar.home"),
          shop: getText("toolbar.shop"),
          cashback: getText("toolbar.cashback"),
        }}
        mobilePanel={{
          copy: {
            favourites: getText("mobile.favourites"),
            home: getText("mobile.home"),
            shop: getText("mobile.shop"),
            cashback: getText("mobile.cashback"),
            account: getText("mobile.account"),
          },
          logo: userData.member.companyLogo,
          secondaryLogo: userData.member.companyBrandLogo,
          logoAlt: "company Logo",
          secondaryLogoAlt: "logo",
        }}
        shopPanel={{
          brands: getOfferSet("header.brands").map((i) => {
            const offer = userData?.catalogue?.offers?.find(
              (o: any) => o.esSupplierId.toString() === i?.mmiId
            );
            return {
              url: `/offer/${offer?.esSupplierOfferId}`,
              image: i?.logo?.url || offer?.imageOfferESURL || "",
              alt: `brand: ${i.name}`,
            };
          }),
          categories: getCategorySet("header.categories")
            .filter((i) => userData.catalogue?.cats?.includes(i?.mmiId || ""))
            .map((i: any) => ({
              label: i.title,
              url: `/category/${i.slug}`,
              alt: `category: ${i.title}`,
            })),
          copy: {
            brandTitle: getText("shop.brandTitle"),
            categoryTitle: getText("shop.categoryTitle"),
            leftColumn: getText("shop.leftColumn"),
            rightColumn: getText("shop.rightColumn"),
          },
          links: getLinkSet("header.shopLinks").map((i) => ({
            title: i.label,
            caption: i.caption,
            url: i.url,
            alt: i.ariaLabel,
          })),
        }}
        searchPanel={{
          copy: {
            offerTitle: getText("search.offers.title"),
            categoryTitle: getText("search.categories.title"),
            noResults: getText("search.no.results"),
          },
        }}
        onLogout={() => {
          window.localStorage.removeItem("access_token");
          window.localStorage.removeItem("refresh_token");
          window.localStorage.removeItem("member_id");
          window.location.replace(
            userData?.member?.logOutURL ||
              process.env.REACT_APP_LOGOUT_URL ||
              ""
          );
        }}
      />
    );
  };

  const Footer = () => {
    const { data, loading: copyLoading } = useGetCopy({ key: "global.footer" });

    const { getText, getLinkSet, getAssetSet } = composeResource(data);

    if (loading) {
      return null;
    }

    return (
      <>
        {!copyLoading && data && (
          <FooterComponent
            column1Links={getLinkSet("footer.column1")}
            column2Links={getLinkSet("footer.column2")}
            copy={{
              column1title: getText("footer.column1.title"),
              column2title: getText("footer.column2.title"),
              legalCopy: getText("footer.legal"),
            }}
            logo={getAssetSet("footer.logo")?.url || ""}
          />
        )}
      </>
    );
  };

  const SimpleNav = ({ basket }: { basket?: boolean }) => {
    const { data } = useGetCopy({ key: "global.header" });

    const { getText } = composeResource(data);

    if (loading || !userData.member) {
      return null;
    }

    return (
      <SimplifiedNavigation
        logo={userData.member.companyLogo}
        logoAlt={""}
        sitename={userData.member.memberCompanyLocation.company.companyName}
        secondaryLogo={userData.member.companyBrandLogo}
        secondaryLogoAlt={""}
        linkText={
          basket
            ? getText("simple.link.basket")
            : getText("simple.link.checkout")
        }
        linkURL={basket ? "/categories" : "/basket"}
      />
    );
  };

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/">
          <Nav />
          <Home />
          <Footer />
        </Route>
        <Route path="/categories">
          <Nav />
          <Categories />
          <Footer />
        </Route>
        <Route path="/category/:id">
          <Nav />
          <Category />
          <Footer />
        </Route>
        <Route path="/campaign/:id">
          <Nav />
          <Campaign />
          <Footer />
        </Route>
        <Route path="/offer/:id">
          <Nav />
          <Offer />
          <Footer />
        </Route>
        <Route path="/basket">
          <SimpleNav basket />
          <Basket />
          <Footer />
        </Route>
        <Route path="/checkout">
          <SimpleNav />
          <Checkout />
          <Footer />
        </Route>
        <Route path="/reloadable-cards">
          <Nav />
          <ReloadableCards />
          <Footer />
        </Route>
        <Route path="/account/profile">
          <Nav />
          <MyDetails />
          <Footer />
        </Route>
        <Route path="/account/order-history">
          <Nav />
          <MyOrderHistory />
          <Footer />
        </Route>
        <Route path="/account/security">
          <Nav />
          <Security />
          <Footer />
        </Route>
        <Route path="/account/notifications">
          <Nav />
          <Notifications />
          <Footer />
        </Route>
        <Route path="/order/:status">
          <Nav />
          <OrderStatus />
          <Footer />
        </Route>
        <Route path="/favourites">
          <Nav />
          <Favourites />
          <Footer />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Suspense>
  );
}
