import { makeStyles, Box, Theme } from "@material-ui/core";
import React, { ReactNode, useEffect, useState } from "react";

interface styleProps {
  image?: string | null;
  ratio: string;
  rounded: boolean | undefined;
  contain: boolean | undefined;
}

export type RatioType = "1:1" | "3:2" | "16:9";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    position: "relative",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: (props: styleProps) =>
      props.contain ? "contain" : "cover",
    backgroundPosition: "center center",
    backgroundColor: (props: styleProps) =>
      props.contain ? "white" : theme.palette.grey[200],
    backgroundImage: (props: styleProps) =>
      props.image ? `url(${props.image})` : "",
    paddingBottom: (props: styleProps) => props.ratio,
    borderRadius: (props: styleProps) =>
      props.rounded ? theme.shape.borderRadius : 0,
  },
}));

export const getRatioPercent = (ratio?: RatioType): string => {
  if (ratio === "3:2") {
    return "66.6%";
  }
  if (ratio === "16:9") {
    return "56.25%";
  }
  return "100%";
};

export function Ratio({
  aria,
  image,
  ratio,
  rounded,
  children,
  contain,
}: {
  aria: string;
  image?: string;
  ratio?: RatioType;
  rounded?: boolean;
  children?: ReactNode;
  contain?: boolean;
}) {
  const [source, setSource] = useState<string>("");

  useEffect(() => {
    const img = new Image();
    img.src = image || "";
    img.onload = () => setSource(image || "");
  }, [image]);

  const classes = useStyles({
    image: source,
    ratio: getRatioPercent(ratio),
    rounded,
    contain,
  });

  return (
    <Box className={classes.box} aria-label={aria}>
      {children}
    </Box>
  );
}

Ratio.defaultProps = {
  ratio: "1:1",
};
