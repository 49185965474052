import {
  Box,
  Collapse,
  Fade,
  Grid,
  IconButton,
  Slide,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { BasketArrowIcon } from "../Icons/basketArrow";
import { Close, DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { BasketIcon } from "../Icons/basket";
import { PrimaryButton, SecondaryButton } from "../Button";
import { basketItem, useGetCart } from "../../contexts/cartContext";
import { Ratio } from "../Ratio";
import { useGetFilteredOffers } from "../../api/queries/filteredOffers";
import { useGetUser } from "../../contexts/userContext";
import { useEffect, useState } from "react";
import { useGetCopy } from "../../api/queries/copy";
import { composeResource } from "../../utils/composeResource";
import { calculateBasket, formatCurrency } from "../../utils/formatCurrency";
import { useHistory } from "react-router-dom";
import { BasketEmptyIcon } from "../Icons/basketEmpty";
import { Alert } from "@material-ui/lab";

export function BasketPanel({ basketCount }: { basketCount: string }) {
  const classes = useSearchStyles();
  const history = useHistory();

  const {
    data: cartData,
    remove,
    isBasketModalOpen,
    setIsBasketModalOpen,
  } = useGetCart();
  const { data } = useGetFilteredOffers({
    variables: { ids: cartData.map((i: basketItem) => i.id) },
  });
  const { data: copyData } = useGetCopy({ key: "global.basket" });
  const { getText } = composeResource(copyData);
  const calculatedBasket = calculateBasket(cartData);
  const { logActivity } = useGetUser();
  const [toast, setToast] = useState(false);

  const handleClose = (value: string) => {
    setIsBasketModalOpen(value);
  };

  useEffect(() => {
    logActivity({ activity: "PAGE_LOAD", page: "MyBag" });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isBasketModalOpen === "added" || isBasketModalOpen === "updated") {
      setToast(true);
      setTimeout(() => setToast(false), 2000);
    }
  }, [isBasketModalOpen]);

  return (
    <div>
      <IconButton
        aria-label="Basket"
        color="primary"
        onClick={() => handleClose("open")}
      >
        <Box width="24px" height="24px">
          <BasketIcon width={16} />
        </Box>
      </IconButton>

      <div>
        <Fade in={isBasketModalOpen !== ""} unmountOnExit>
          <div
            className={classes.basketBg}
            onClick={() => handleClose("")}
          ></div>
        </Fade>

        <Slide
          direction="down"
          in={isBasketModalOpen !== ""}
          mountOnEnter
          unmountOnExit
          timeout={700}
        >
          <div className={classes.basketContainer}>
            <BasketArrowIcon
              style={{
                position: "absolute",
                width: "32px",
                height: "18px",
                top: "-17px",
                right: "41px",
              }}
            />
            <Grid container className={classes.basketTopBar}>
              <Grid item className={classes.heading}>
                <div className={classes.bag}>{getText("basket.title")}</div>
                <div className={classes.count}>({basketCount || 0}) items</div>
              </Grid>
              <Grid item className={classes.close}>
                <IconButton onClick={() => handleClose("")}>
                  <Close color="primary" />
                </IconButton>
              </Grid>
            </Grid>
            {cartData.length ? (
              <Grid container className={classes.middlesection}>
                <Grid>
                  <Collapse in={toast} mountOnEnter unmountOnExit>
                    <Alert
                      style={{ borderRadius: "0px" }}
                      severity="success"
                      variant="filled"
                    >
                      Item {isBasketModalOpen} in your bag
                    </Alert>
                  </Collapse>
                  <Grid container className={classes.itemContainer}>
                    {cartData.map((item: basketItem, index: number) => {
                      const offer = data?.offerCollection?.items.find(
                        (offer) => offer?.mmiId === item.id
                      );

                      return (
                        <Grid
                          key={`cart--item--${index}`}
                          item
                          className={classes.basketItem}
                          xs={12}
                        >
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item xs={3}>
                              <Ratio
                                image={
                                  offer?.featuredImagesCollection?.items[0]
                                    ?.url || ""
                                }
                                ratio="16:9"
                                rounded
                                aria={
                                  offer?.featuredImagesCollection?.items[0]
                                    ?.description || "Product image"
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container direction="column">
                                <Grid item xs>
                                  <Typography
                                    variant="body1"
                                    className={classes.bhead}
                                  >
                                    {offer?.name}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bsubhead}
                                  >
                                    {item?.name} {formatCurrency(item.amount)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={3}>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      // @ts-ignore
                                      window.gtag("event", "view_item", {
                                        items: [
                                          {
                                            id: item.offerId,
                                            name: item.name,
                                            list_name: "Basket",
                                            brand: item.brand,
                                            variant: item.purchaseOption,
                                            list_position: index,
                                            quantity: 1,
                                            price: item.rawTotals?.total || 0,
                                          },
                                        ],
                                      });
                                      handleClose("");
                                      history.push("/offer/" + item.offerId);
                                      //to={`/offer/${item.offerId}`}
                                    }}
                                  >
                                    <EditOutlined>
                                      {getText("basket.edit.label")}
                                    </EditOutlined>
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={() => remove(item.id)}
                                  >
                                    <DeleteOutlined width={24} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <div className={classes.sectioncover}>
                                <span className={classes.blacksection}>
                                  {`${getText("basket.payment.label")} ${
                                    item.totals.total
                                  }`}
                                </span>
                                <span className={classes.redsection}>
                                  {`${getText("basket.saving.label")} ${
                                    item.totals.saving
                                  }`}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  {calculatedBasket.total && (
                    <Grid
                      container
                      spacing={2}
                      direction="column"
                      className={classes.totals}
                    >
                      <Grid className={classes.flexGrow}>
                        <Typography
                          align="right"
                          variant="body2"
                          className={classes.totalpay}
                        >
                          {getText("basket.totals.total")}{" "}
                          {calculatedBasket.total}
                        </Typography>
                        {calculatedBasket.saving && (
                          <Typography
                            variant="body2"
                            align="right"
                            color="error"
                            className={classes.totalsaved}
                          >
                            {`${getText("basket.savings.total")} ${
                              calculatedBasket.saving
                            }`}
                          </Typography>
                        )}
                      </Grid>
                      <Grid container item spacing={2}>
                        <Grid item xs={6}>
                          <Typography align="center">
                            <SecondaryButton
                              fullWidth
                              className={classes.inlineBlock}
                              onClick={() => {
                                handleClose("");
                                history.push("/basket");
                              }}
                            >
                              {getText("basket.view")}
                            </SecondaryButton>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="center">
                            <PrimaryButton
                              fullWidth
                              className={classes.inlineBlock}
                              onClick={() => {
                                handleClose("");
                                history.push("/checkout");
                              }}
                            >
                              {getText("basket.checkout.link")}
                            </PrimaryButton>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid className={classes.emptyBag}>
                <BasketEmptyIcon
                  style={{
                    width: "80px",
                    height: "80px",
                  }}
                />
                <Typography component="span" className={classes.bgemptytxt}>
                  {getText("basket.empty")}
                </Typography>
              </Grid>
            )}
          </div>
        </Slide>
      </div>
    </div>
  );
}

const useSearchStyles = makeStyles((theme: Theme) => ({
  basketBg: {
    position: "fixed",
    top: "0%",
    left: "0%",
    background: "#00000063",
    width: "100vw",
    height: "100vh",
  },
  basketCount: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    width: "20px",
    height: "20px",
    backgroundColor: theme.palette.error.main,
    color: "white",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  basketContainer: {
    position: "absolute",
    border: "1px solid #F4F7F9",
    maxWidth: "358px",
    top: "57px",
    right: "2px",
    background: "#fff",
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      top: "64px",
      right: "-35px",
    },
  },
  basketTopBar: {
    display: "flex",
    padding: "8px 16px",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    color: "#000",
    background: "#F4F7F9",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    flex: "1 0 0",
  },
  bag: {
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  count: {
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  middlesection: {},
  close: {
    display: "flex",
    alignItems: "center",
  },
  itemContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxHeight: "50vh",
    overflow: "auto",
    paddingBottom: "7px",
    background:
      "linear-gradient(#00000017 0%, white 10%, white 90%, #00000017 100%)",
  },
  basketItem: {
    borderBottom: `1px solid #bdbdbd3d`,
    padding: "13px 0px 0 0",
  },
  inlineBlock: {
    display: "inline-block",
  },
  totals: {
    margin: "0",
    width: "100%",
  },
  flexGrow: {
    padding: "14px",
  },
  sectioncover: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  redsection: {
    color: "red",
    float: "right",
    marginRight: "5px",
  },
  blacksection: {
    color: "#000",
    float: "right",
    marginRight: "5px",
  },
  bhead: {
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#000",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  bsubhead: {
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#000",
  },
  totalpay: {
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#000",
  },
  totalsaved: {
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  emptyBag: {
    maxWidth: "358px",
    display: "inline-flex",
    padding: "16px",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    width: "100%",
  },
  bgemptytxt: {
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#000",
  },
}));
