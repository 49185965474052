import { stringify } from "qs";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ProductTypes } from "../utils/productTypes";
import { useGetUser } from "./userContext";

interface CartContextType {
  data: basketItem[];
  isBasketModalOpen: string;
  add: (item: basketItem) => void;
  update: (item: basketItem, id: string) => void;
  remove: (id: string) => void;
  refresh: () => void;
  setIsBasketModalOpen: (open: string) => void;
}

export const CartContext = React.createContext<CartContextType>({
  add: () => null,
  update: () => null,
  remove: () => null,
  refresh: () => null,
  data: [],
  isBasketModalOpen: "",
  setIsBasketModalOpen: () => {},
});

export interface basketItem {
  id: string;
  offerId: string;
  totals: {
    total: string;
    saving: string;
  };
  rawTotals: {
    total: number;
    saving: number;
  };
  amount: number;
  purchaseOption: keyof ProductTypes;
  contract: Contract;
  topUp?: string;
  name: string;
  brand: string;
}

export type ContractType = 1 | 2 | 3 | 4;

export interface Contract {
  maximumDefaultAmount: number;
  minimumDefaultAmount: number;
  discountPct: number;
  lowestDenomination: number;
  supplierTypeID: ContractType;
  contractSupplierID: number;
  parentSupplierID: number;
  supplierID: number;
  supplierDescription: string;
  setUpCost: number;
  supplierTypeName: string;
  setAmount1: number;
  setAmount2: number;
  setAmount3: number;
  isInstantDelivery: boolean;
}

export const CartProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { push } = useHistory();
  const existing = window.localStorage.getItem("basket");
  const [basket, setBasket] = useState<basketItem[]>([]);
  const { logActivity } = useGetUser();
  const [isBasketModalOpen, setIsBasketModalOpen] = useState<string>("");

  const updateBasket = (newBasket: basketItem[]) => {
    // basket items need to have IDs so they can be deleted
    newBasket = newBasket.filter((item) => !!item.id);
    try {
      localStorage.setItem("basket", JSON.stringify(newBasket));
      setBasket(newBasket);
    } catch (e) {
      console.error(`${e}:: Error updating  basket`);
    }
  };

  React.useEffect(() => {
    if (existing) {
      try {
        updateBasket(JSON.parse(existing));
      } catch (e) {
        console.error(`Error parsing Basket:: ${e}`);
      }
    }
    // eslint-disable-next-line
  }, []);

  const add = (item: basketItem) => {
    const overallTotal =
      basket.reduce((acc, cur) => acc + cur.amount, 0) + item.amount;

    if (overallTotal > 10000) {
      push({
        search: stringify({ alert: 6 }),
      });
      return;
    }

    updateBasket([...basket, item]);
    setIsBasketModalOpen("added");
    // @ts-ignore
    window.gtag("event", "add_to_cart", {
      items: [
        {
          item_id: item.offerId,
          item_name: item.name,
          item_brand: item.brand,
          item_variant: item.purchaseOption,
          quantity: 1,
          price: item.rawTotals.total,
        },
      ],
    });

    logActivity({
      activity: "ADD_BASKET",
      page: "offer",
      identifier: `${item.id}`,
    });
  };

  const update = (item: basketItem, id: string) => {
    const overallTotal = basket.reduce((acc, cur) => {
      return acc + (cur.id === item.id ? item.amount : cur.amount);
    }, 0);
    if (overallTotal > 10000) {
      push({
        search: stringify({ alert: 6 }),
      });
      return;
    }

    updateBasket(
      basket.map((i: any) => {
        if (i.id === id) {
          return item;
        }
        return i;
      })
    );
    setIsBasketModalOpen("updated");
  };

  const remove = (id: string): void => {
    updateBasket(
      basket.filter((i) => {
        logActivity({
          activity: "ADD_BASKET",
          page: "basket",
          identifier: `${i.id}`,
        });
        // @ts-ignore
        window.gtag("event", "remove_from_cart", {
          items: [
            {
              item_id: i.offerId,
              item_name: i.name,
              item_brand: i.brand,
              item_variant: i.purchaseOption,
              quantity: 1,
              price: i.rawTotals.total,
            },
          ],
        });
        return i.id !== id;
      })
    );
  };

  const refresh = (): void => {
    const basket = window.localStorage.getItem("basket");
    if (basket) {
      try {
        updateBasket(JSON.parse(basket));
      } catch (e) {
        console.error(`Error parsing Basket:: ${e}`);
      }
    }
  };

  const value = {
    data: basket,
    add,
    update,
    remove,
    refresh,
    isBasketModalOpen,
    setIsBasketModalOpen,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useGetCart = (): CartContextType => {
  return useContext(CartContext);
};
