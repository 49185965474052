import React, { useState } from "react";
import {
  Container,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  Box,
} from "@material-ui/core";
import { CloseOutlined, Search } from "@material-ui/icons";
import { Card } from "../Card";
import { useGetOfferSearch } from "../../api/queries/offerSearch";
import { useGetCategorySearch } from "../../api/queries/categorySearch";
import { getDiscountTitle } from "../../utils/getDiscountTitle";
import { useGetUser } from "../../contexts/userContext";
import { SkeletonCardGridFourWide } from "../Skeleton";

const useSearchStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
  drawer: {
    zIndex: 3000,
  },
  input: {
    border: "none",
    background: "transparent",
    flex: 1,
    "&:focus": {
      outline: "none",
    },
  },
  inputContainer: {
    backgroundColor: theme.palette.grey[200],
    display: "flex",
    alignItems: "center",
    borderRadius: "42px",
    maxWidth: "450px",
    flex: 1,
  },
  titles: {
    paddingBottom: theme.spacing(2),
  },
  header: {
    position: "sticky",
    top: 0,
    zIndex: 100,
    background: "white",
  },
}));

export function SearchPanel({
  open,
  onClose,
  copy,
}: {
  open: boolean;
  onClose: () => void;
  copy: {
    offerTitle: string;
    categoryTitle: string;
    noResults: string;
  };
}) {
  const [searchString, setSearchString] = useState<string | undefined>("");

  const {
    data: { catalogue },
    addFavourite,
  } = useGetUser();

  const { data: OSData, loading: OSLoading } = useGetOfferSearch({
    variables: { search: searchString, ids: catalogue?.ids || [] },
    fetchPolicy: "network-only",
  });

  const { data: CSData, loading: CSLoading } = useGetCategorySearch({
    variables: { search: searchString },
    fetchPolicy: "network-only",
  });

  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchString(value);
  };

  const classes = useSearchStyles();
  const resultsExist = () => {
    if (CSLoading || OSLoading) {
      return true;
    }

    if (Boolean(OSData?.offerCollection?.items.length)) {
      return true;
    }

    if (Boolean(CSData?.categoryCollection?.items.length)) {
      return true;
    }

    return false;
  };

  // @ts-ignore
  window.gtag("event", "view_item_list", {
    items: OSData?.offerCollection?.items
      .filter((i) => catalogue?.ids?.includes(i?.mmiId || ""))
      .map((item, index) => {
        const offers = catalogue?.offers?.filter(
          (o: any) => o.esSupplierId.toString() === item?.mmiId
        );
        return offers?.map((offer) => ({
          id: offer.esSupplierOfferId,
          name: !offer?.ssvRetailerVouchers
            ? offer?.esShortDescription || ""
            : getDiscountTitle(item?.mmiId, catalogue),
          list_name: "Search Results",
          brand: item?.name,
          list_position: index,
        }));
      })
      .flat(2),
  });

  return (
    <Drawer
      className={classes.drawer}
      anchor="top"
      open={open}
      onClose={onClose}
      style={{
        zIndex: 3001,
      }}
    >
      <Container className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.header}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item className={classes.inputContainer}>
                <Search />
                <input
                  type="search"
                  className={classes.input}
                  onChange={search}
                  value={searchString}
                />
              </Grid>
              <Hidden smUp>
                <Grid item>
                  <IconButton onClick={onClose}>
                    <CloseOutlined width={24} />
                  </IconButton>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          {!resultsExist() && (
            <Grid item xs={12}>
              <Box padding={4}>
                <Typography align="center" variant="h3">
                  {copy.noResults}
                </Typography>
              </Box>
            </Grid>
          )}
          {resultsExist() && (
            <>
              <Grid item xs={12}>
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <Grid container>
                      {!OSLoading && OSData?.offerCollection?.items.length ? (
                        <Grid item xs={12}>
                          <Typography
                            className={classes.titles}
                            variant="body1"
                            gutterBottom
                          >
                            {copy.offerTitle}
                          </Typography>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        {!OSLoading ? (
                          <Grid container spacing={4}>
                            {OSData?.offerCollection?.items.map((card) => {
                              const offers = catalogue?.offers?.filter(
                                (o: any) =>
                                  o.esSupplierId.toString() === card?.mmiId
                              );

                              return card && offers?.length
                                ? offers.map((offer, index) => (
                                    <Grid
                                      item
                                      xs={6}
                                      sm={3}
                                      key={`${card?.mmiId}-${index}`}
                                    >
                                      <Card
                                        title={
                                          !offer?.ssvRetailerVouchers
                                            ? offer?.esShortDescription || ""
                                            : getDiscountTitle(
                                                card?.mmiId,
                                                catalogue
                                              )
                                        }
                                        ariaImageLabel={
                                          card?.featuredImagesCollection
                                            ?.items[0]?.description || ""
                                        }
                                        subTitle={card?.name || ""}
                                        to={`/offer/${offer.esSupplierOfferId}`}
                                        key={`card-${index}`}
                                        image={
                                          card?.featuredImagesCollection
                                            ?.items[0]?.url || ""
                                        }
                                        logoImage={
                                          card?.logo?.url ||
                                          offer?.imageOfferESURL
                                        }
                                        isReloadable={card?.reloadable || false}
                                        onFavourite={() =>
                                          addFavourite(card?.mmiId || "")
                                        }
                                        isFavourite={offer && offer.isFavourite}
                                        inStore={card?.inStore || false}
                                        isNew={card?.isNew || false}
                                        isSelling={card?.fastSelling || false}
                                      />
                                    </Grid>
                                  ))
                                : null;
                            })}
                          </Grid>
                        ) : (
                          <SkeletonCardGridFourWide amount={4} />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      {CSData?.categoryCollection?.items.length &&
                      !CSLoading ? (
                        <Grid item xs={12}>
                          <Typography
                            className={classes.titles}
                            variant="body1"
                            gutterBottom
                          >
                            {copy.categoryTitle}
                          </Typography>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        {!CSLoading ? (
                          <Grid container spacing={4}>
                            {CSData?.categoryCollection?.items
                              ?.filter((i) =>
                                catalogue?.cats?.includes(i?.mmiId || "")
                              )
                              .map((card, index) => (
                                <Grid
                                  item
                                  style={{ margin: 0 }}
                                  xs={6}
                                  sm={3}
                                  key={`search-cat--${index}`}
                                >
                                  <Card
                                    title={card?.title || ""}
                                    to={`/category/${card?.slug || ""}`}
                                    image={card?.image?.url || ""}
                                    ariaImageLabel={
                                      card?.image?.description || ""
                                    }
                                  />
                                </Grid>
                              )) || null}
                          </Grid>
                        ) : (
                          <SkeletonCardGridFourWide amount={4} />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Drawer>
  );
}
