import React from "react";
import {
  Container,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { useNavigationStyles } from "./styles";
import { Ratio } from "../Ratio";

export function ShopPanel({
  onClose,
  brands,
  categories,
  links,
  copy,
}: shopPanelNavigationProps) {
  const classes = useNavigationStyles();
  return (
    <Container id="shop-menu" className={classes.shopContainer}>
      <Grid container className={`${classes.shopPanel}`} spacing={0}>
        <Grid
          item
          xs={12}
          md={6}
          className={`${classes.borderRight} ${classes.shopLeftColumn}`}
        >
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <Hidden mdUp>
                <IconButton
                  aria-label="go back"
                  onClick={onClose}
                  className={classes.iconButton}
                >
                  <ChevronLeft color="primary" />
                </IconButton>
              </Hidden>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.panelTitle}
              >
                {copy.leftColumn}
              </Typography>
              <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12} className={classes.paddingLeftZero}>
              <Grid
                container
                spacing={1}
                className={classes.linksList}
                direction="column"
              >
                {links.map((link, index) => (
                  <Grid item key={`shop-link--${index}`}>
                    <NavLink
                      className={classes.navLink}
                      activeClassName="active"
                      to={link.url}
                    >
                      <Typography variant="h5">{link.title}</Typography>
                      <Typography variant="caption">{link.caption}</Typography>
                    </NavLink>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.shopRightColumn}>
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.panelTitle}
              >
                {copy.rightColumn}
              </Typography>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.categoryText}
                variant="overline"
                color="secondary"
              >
                {copy.categoryTitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                className={`${classes.linksList} ${classes.categoryList}`}
              >
                {categories.map((cat, index) => (
                  <Grid
                    item
                    xs={12}
                    className={classes.category}
                    md="auto"
                    key={`menu-cats--${index}`}
                  >
                    <NavLink
                      className={classes.navLink}
                      activeClassName="active"
                      to={cat.url}
                      aria-label={cat.alt}
                    >
                      <Typography variant="body1">{cat.label}</Typography>
                    </NavLink>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12}>
                <Typography
                  className={classes.categoryText}
                  variant="overline"
                  color="secondary"
                >
                  {copy.brandTitle}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {brands.map((brand, index) => (
                    <Grid item xs={4} key={`menu-brands--${index}`}>
                      <NavLink
                        className={classes.imageLink}
                        activeClassName="active"
                        to={brand.url}
                        aria-label={brand.alt}
                      >
                        <Ratio
                          image={brand.image}
                          contain
                          rounded
                          aria={brand.alt}
                          ratio="16:9"
                        />
                      </NavLink>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export interface shopPanelNavigationProps {
  onClose: () => void;
  brands: {
    image: string;
    alt: string;
    url: string;
  }[];
  categories: {
    label: string;
    alt: string;
    url: string;
  }[];
  links: {
    title: string;
    caption?: string;
    url: string;
    alt: string;
  }[];
  copy: {
    rightColumn: string;
    leftColumn: string;
    brandTitle: string;
    categoryTitle: string;
  };
}
