import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Hidden,
  Box,
  useScrollTrigger,
  Drawer,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {
  FavoriteBorderOutlined,
  KeyboardArrowDown,
  PermIdentityOutlined,
  Search,
} from "@material-ui/icons";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { ShopPanel } from "./ShopPanel";
import { AccountPanel } from "./AccountPanel";
import { useNavigationStyles } from "./styles";
import { MobilePanel } from "./MobilePanel";
import { SearchPanel } from "./SearchPanel";
import { TextLink } from "../Button";
import { useGetUser } from "../../contexts/userContext";
import { CashbackPanel } from "./CashbackPanel";
import { PageLoader } from "../PageLoader";
import { Toast } from "../Toast";
import { composeResource } from "../../utils/composeResource";
import { useGetCopy } from "../../api/queries/copy";
import { BasketPanel } from "./BasketPanel";
export interface NavigationProps {
  accountPanel: {
    links: {
      title: string;
      caption?: string;
      url: string;
      alt: string;
    }[];
    copy: {
      logout: string;
      title: string;
    };
  };
  shopPanel: {
    brands: {
      image: string;
      alt: string;
      url: string;
    }[];
    categories: {
      label: string;
      alt: string;
      url: string;
    }[];
    links: {
      title: string;
      caption?: string;
      url: string;
      alt: string;
    }[];
    copy: {
      rightColumn: string;
      leftColumn: string;
      brandTitle: string;
      categoryTitle: string;
    };
  };
  mobilePanel: {
    logo: string;
    logoAlt: string;
    secondaryLogo: string;
    secondaryLogoAlt: string;
    copy: {
      home: string;
      shop: string;
      cashback: string;
      favourites: string;
      account: string;
    };
  };
  logo: string;
  logoAlt: string;
  secondaryLogo: string;
  secondaryLogoAlt: string;
  sitename: string;
  basketCount?: string;
  hasAccessToCashBack?: boolean;
  copy: {
    home: string;
    shop: string;
    cashback: string;
  };
  searchPanel: {
    copy: {
      offerTitle: string;
      categoryTitle: string;
      noResults: string;
    };
  };
  onLogout: () => void;
}

export const SimplifiedNavigation: React.FC<{
  logo: string;
  logoAlt: string;
  secondaryLogo: string;
  secondaryLogoAlt: string;
  sitename: string;
  linkText: string;
  linkURL: string;
}> = ({
  logo,
  logoAlt,
  secondaryLogo,
  secondaryLogoAlt,
  sitename,
  linkText,
  linkURL,
}) => {
  const classes = useNavigationStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return (
    <AppBar
      position="sticky"
      elevation={trigger ? 2 : 0}
      className={classes.appbar}
    >
      <Container style={{ height: "100%" }}>
        <Grid
          container
          style={{ height: "100%" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              role="banner"
              component={Link}
              to="/"
              spacing={2}
              wrap="nowrap"
            >
              <Grid item>
                <img className={classes.logo} src={logo} alt={logoAlt} />
              </Grid>
              <Grid item>
                <img
                  className={classes.logo}
                  src={secondaryLogo}
                  alt={secondaryLogoAlt}
                />
              </Grid>
              <h1 className={classes.visuallyHidden}>{sitename}</h1>
            </Grid>
          </Grid>
          <Grid item>
            <TextLink to={linkURL} aria-label={linkText}>
              {linkText}
            </TextLink>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export const Navigation: React.FC<NavigationProps> = ({
  accountPanel,
  shopPanel,
  mobilePanel,
  logo,
  logoAlt,
  secondaryLogo,
  secondaryLogoAlt,
  sitename,
  copy,
  searchPanel,
  basketCount,
  hasAccessToCashBack,
  onLogout,
}) => {
  const panels = {
    mobile: "mobile",
    search: "search",
    shop: "shop",
    account: "account",
  };
  const [activePanel, setActivePanel] = useState<null | string>(null);
  const classes = useNavigationStyles();
  const { push } = useHistory();
  const containerRef = useRef(null);
  const accountWidth = containerRef.current
    ? containerRef.current?.["clientWidth"]
    : 0;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  const location = useLocation();
  const { userAcceptedCashbackTnCs } = useGetUser();
  const [openCashbackModal, setOpenCasbackModal] = React.useState(false);
  const [cashbackLoader, setCashbackLoader] = React.useState(false);
  const [toast, setToast] = React.useState(false);
  const { data: copyData } = useGetCopy({ key: "global.cashback" });
  const { getText } = composeResource(copyData);

  const opnCashbackModal = () => {
    setOpenCasbackModal(true);
  };
  const closeCashbackModal = () => {
    setOpenCasbackModal(false);
  };

  useEffect(() => {
    setActivePanel(null);
  }, [location.pathname]);

  const accountsPage = new RegExp(
    ["/account", "/reloadable-cards"].join("|")
  ).test(location.pathname);

  const shopPage = new RegExp(
    ["/category", "/categories", "/offer"].join("|")
  ).test(location.pathname);

  // Page loader
  if (cashbackLoader) {
    return (
      <div
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          zIndex: 9,
          opacity: 0.8,
        }}
      >
        <PageLoader />
      </div>
    );
  }

  const callCashback = async () => {
    try {
      setCashbackLoader(true);
      // @ts-ignore
      window.gtag("event", "redirect_to_cashback", {
        event_category: "cashback",
      });

      let res = await userAcceptedCashbackTnCs();
      // Check if TnCs are accepted
      if (
        res === null ||
        !res.data.content.hasAcceptedCashBackTsAndCs ||
        window.localStorage.getItem("userCashBackTermsVersion") !==
          getText("cashback.terms_version")
      ) {
        opnCashbackModal();
        return;
      }
      const link = res.data.content.ssoUrl;
      if (!link) throw Error();
      setTimeout(() => {
        window.open(link);
      });
    } catch (error) {
      setToast(true);
      //console.log(error);
    } finally {
      setCashbackLoader(false);
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        elevation={trigger ? 2 : 0}
        className={classes.appbar}
      >
        <Container>
          <Toolbar disableGutters ref={containerRef}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={6} md={4}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  role="banner"
                  component={Link}
                  to="/"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item>
                    <img className={classes.logo} src={logo} alt={logoAlt} />
                  </Grid>
                  <Grid item>
                    <img
                      className={classes.logo}
                      src={secondaryLogo}
                      alt={secondaryLogoAlt}
                    />
                  </Grid>
                  <h1 className={classes.visuallyHidden}>{sitename}</h1>
                </Grid>
              </Grid>
              <Hidden smDown>
                <Grid item md={4}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <NavLink
                        to="/"
                        activeClassName="active"
                        className={classes.toolbarLinks}
                        exact
                      >
                        <ListItemText>
                          <Typography color="primary" variant="subtitle1">
                            {copy.home}
                          </Typography>
                        </ListItemText>
                      </NavLink>
                    </Grid>
                    <Grid item>
                      <button
                        onClick={() =>
                          setActivePanel(
                            activePanel === panels.shop ? null : panels.shop
                          )
                        }
                        className={`${classes.toolbarLinks} ${
                          activePanel === panels.shop && "active"
                        } ${shopPage && "active"}`}
                        aria-owns={
                          activePanel === panels.shop ? "shop-menu" : undefined
                        }
                        aria-haspopup="true"
                      >
                        <Typography color="primary" variant="subtitle1">
                          {copy.shop}
                        </Typography>

                        <Box
                          className={classes.shopIcon}
                          style={{
                            transform: `rotate(${
                              activePanel === panels.shop ? 180 : 0
                            }deg)`,
                          }}
                        >
                          <KeyboardArrowDown />
                        </Box>
                      </button>
                    </Grid>
                    {hasAccessToCashBack && (
                      <Grid item>
                        <button
                          onClick={async () => {
                            callCashback();
                          }}
                          className={classes.toolbarLinks}
                        >
                          <Typography color="primary" variant="subtitle1">
                            {copy.cashback}
                          </Typography>
                        </button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Hidden>
              <Grid item xs={6} md={4}>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  role="navigation"
                  aria-label="Shop navigation"
                >
                  <IconButton
                    aria-label="Search"
                    color="primary"
                    onClick={() =>
                      setActivePanel(
                        activePanel === panels.search ? null : panels.search
                      )
                    }
                  >
                    <Search width="24px" />
                  </IconButton>
                  <Hidden smDown>
                    <IconButton
                      aria-label="Favourites"
                      color="primary"
                      onClick={() => push("/favourites")}
                    >
                      <FavoriteBorderOutlined width="24px" />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        setActivePanel(
                          activePanel === panels.account ? null : panels.account
                        )
                      }
                      aria-label="Account Menu"
                      color="primary"
                      aria-owns={
                        activePanel === panels.account
                          ? "account-menu"
                          : undefined
                      }
                      aria-haspopup="true"
                    >
                      <PermIdentityOutlined width="24px" />
                    </IconButton>
                  </Hidden>
                  <BasketPanel basketCount={basketCount || ""} />
                  <Hidden mdUp>
                    <IconButton
                      edge="end"
                      aria-label="Menu"
                      color="primary"
                      onClick={() => setActivePanel(panels.mobile)}
                    >
                      <MenuIcon width="24px" />
                    </IconButton>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <MobilePanel
        {...mobilePanel}
        shopRouteActive={shopPage}
        accountRouteActive={accountsPage}
        open={
          activePanel === panels.mobile ||
          activePanel === panels.shop ||
          activePanel === panels.account
        }
        onClose={() => setActivePanel(null)}
        onOpen={() => setActivePanel(panels.mobile)}
        onOpenShop={() => setActivePanel(panels.shop)}
        onOpenAccount={() => setActivePanel(panels.account)}
        callCashback={callCashback}
        hasAccessToCashBack={hasAccessToCashBack || false}
        shopActive={activePanel === panels.shop}
        accountActive={activePanel === panels.account}
        accountPanel={
          <AccountPanel
            {...accountPanel}
            onClose={() => setActivePanel(panels.mobile)}
            onLogout={onLogout}
          />
        }
        shopPanel={
          <ShopPanel
            {...shopPanel}
            onClose={() => setActivePanel(panels.mobile)}
          />
        }
      />
      <Hidden smDown>
        <Drawer
          anchor="top"
          classes={{
            paper: classes.desktopPosition,
          }}
          open={activePanel === panels.shop}
          onClose={() => setActivePanel(panels.mobile)}
        >
          <ShopPanel
            {...shopPanel}
            onClose={() => setActivePanel(panels.mobile)}
          />
        </Drawer>
        <Drawer
          classes={{
            paper: classes.accountPosition,
          }}
          PaperProps={{
            style: {
              width: accountWidth ? accountWidth / 2 : "100%",
            },
          }}
          anchor="top"
          open={activePanel === panels.account}
          onClose={() => setActivePanel(panels.mobile)}
        >
          <AccountPanel
            {...accountPanel}
            onClose={() => setActivePanel(panels.mobile)}
            onLogout={onLogout}
          />
        </Drawer>
      </Hidden>
      <SearchPanel
        open={activePanel === panels.search}
        onClose={() => setActivePanel(null)}
        copy={searchPanel.copy}
      />
      <CashbackPanel openmod={openCashbackModal} onClose={closeCashbackModal} />
      <Toast
        open={toast}
        type={"error"}
        text={getText("cashback.toast_error")}
        onClose={() => {
          setToast(false);
        }}
      />
    </>
  );
};
