import { ReactNode } from "react";
import {
  makeStyles,
  Modal as MuiModal,
  Box,
  Backdrop,
  IconButton,
  Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 370,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: "block",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export function Modal({
  children,
  open = false,
  onClose,
  title,
  footer,
  ariaDescribedby,
}: {
  children?: ReactNode | null;
  open?: boolean;
  onClose?: () => void;
  title?: string;
  ariaDescribedby?: string;
  footer?: any;
}) {
  const classes = useStyles();
  const modalStyle = getModalStyle();
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby={title}
      aria-describedby={ariaDescribedby}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div style={modalStyle} className={classes.paper}>
        <header>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <h2>{title}</h2>
            </Box>
            <Box>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </header>
        <Typography
          component="div"
          // 👇 Content box styled to scroll when overflowing
          style={{ maxHeight: "50vh", overflowY: "auto" }}
        >
          <>{children}</>
        </Typography>
        <footer>{footer}</footer>
      </div>
    </MuiModal>
  );
}
