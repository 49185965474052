import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./styles/theme";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./Routes";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import { client } from "./client";
import { UserProvider } from "./contexts/userContext";
import SwiperCore, {
  Navigation,
  A11y,
  Keyboard,
  EffectFade,
  Pagination,
  Parallax,
  Autoplay,
  Thumbs,
} from "swiper";
import "swiper/swiper.scss";
import { CartProvider } from "./contexts/cartContext";
import { AlertProvider } from "./contexts/alertContext";
import ScrollToTop from "./utils/ScrollToTop";
import { useLoadAppInsights } from "./utils/hooks/useLoadAppInsights";

function App() {
  SwiperCore.use([
    Navigation,
    A11y,
    Keyboard,
    EffectFade,
    Pagination,
    Parallax,
    Autoplay,
    Thumbs,
  ]);

  useLoadAppInsights();

  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop />
        <UserProvider>
          <CartProvider>
            <HelmetProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AlertProvider>
                  <Routes />
                </AlertProvider>
              </ThemeProvider>
            </HelmetProvider>
          </CartProvider>
        </UserProvider>
      </Router>
    </ApolloProvider>
  );
}

export default App;
