import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { filteredOffers } from "../../types/generated/filteredOffers";

export const FILTERED_OFFERS_QUERY = gql`
  query filteredOffers($ids: [String], $order: [OfferOrder]) {
    offerCollection(where: { mmiId_in: $ids }, order: $order, limit: 800) {
      items {
        name
        sys {
          id
        }
        logo {
          url
        }
        categoriesCollection {
          items {
            mmiId
            title
          }
        }
        featuredImagesCollection(limit: 1) {
          items {
            url
            title
            description
          }
        }
        mmiId
        reloadable
      }
    }
  }
`;

export const useGetFilteredOffers = (args: QueryHookOptions<filteredOffers>) =>
  useQuery<filteredOffers>(FILTERED_OFFERS_QUERY, args);
