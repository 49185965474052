import { makeStyles, Theme } from "@material-ui/core";

export const useNavigationStyles = makeStyles((theme: Theme) => ({
  appbar: {
    backgroundColor: "white",
    height: "56px",
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      height: "64px",
      zIndex: 3000,
    },
  },
  logo: {
    maxHeight: "44px",
    maxWidth: "100%",
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      maxHeight: "56px",
    },
  },
  mobileMenu: {
    "& .MuiDrawer-paper": {
      width: "80%",
      overflowY: "hidden",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(0),
    },
  },
  mobilePanel: {
    position: "absolute",
    top: "57px",
    left: 0,
    background: "white",
    zIndex: theme.zIndex.snackbar,
    padding: 0,
    margin: 0,
    maxWidth: "100%",
    width: "100%",
    height: "calc(100vh - 54px)",
    display: "block",
    overflowY: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      position: "relative",
      marginTop: theme.spacing(0),
      display: "flex",
      height: "auto",
      overflowY: "auto",
      overflowX: "auto",
      top: 0,
    },
  },
  toolbarLinks: {
    display: "inline-flex",
    margin: 0,
    border: "none",
    background: "transparent",
    cursor: "pointer",
    position: "relative",
    height: "32px",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textDecoration: "none",

    "&::after": {
      transition: "all 0.4s ease",
      content: "''",
      position: "absolute",
      width: "100%",
      height: "4px",
      bottom: "-16px",
      left: 0,
    },

    "&:focus, &:hover": {
      outline: "none",
      "&::after": {
        backgroundColor: theme.palette.grey[700],
      },
    },

    "&.active": {
      "&::after": {
        backgroundColor: theme.palette.grey[900],
      },
    },
  },
  shopIcon: {
    transition: `transform 0.4s ease`,
    minWidth: "24px",
    marginTop: "2px",
  },
  shopPanel: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      width: "100%",
      margin: 0,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  shopLeftColumn: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(8),

      "& > div": {
        display: "block",
      },
    },
  },
  shopRightColumn: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(8),

      "& > div": {
        display: "block",
      },
    },
  },
  accountPanel: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
    },
  },
  accountPosition: {
    top: "64px",
    left: "50%",
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  desktopPosition: {
    top: "64px",
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  navLink: {
    display: "block",
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(3),
    width: `calc(100% + ${theme.spacing(2)})`,
    marginLeft: `-${theme.spacing(2)}`,
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    border: 0,
    outline: "none",
    background: "transparent",
    transition: "background-color 0.4s ease",
    borderBottomLeftRadius: "60px",
    borderTopLeftRadius: "60px",
    cursor: "pointer",
    textDecoration: "none",

    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginLeft: `-${theme.spacing(3)}`,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      width: "auto",
    },

    "&:focus, &:hover": {
      outline: "none",
      backgroundColor: theme.palette.grey[200],
    },

    "&.active": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  imageLink: {
    "&:focus, &:hover": {
      "& div": {
        boxShadow: theme.shadows[2],
      },
    },
  },
  borderRight: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  arrows: {
    paddingLeft: `${theme.spacing(2)} !important`,
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  panelTitle: {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingLeft: 0,
    },
  },
  iconButton: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    marginLeft: `-${theme.spacing(2)}`,
    width: "100%",
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginLeft: 0,
    },
  },
  linksList: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  logoutButton: {
    marginTop: theme.spacing(4),
    width: `calc(100% - ${theme.spacing(8)})`,
    marginLeft: theme.spacing(4),
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      width: "auto",
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  categoryText: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
    display: "block",
    color: "#555555",
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingLeft: 0,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
  },
  paddingLeftZero: {
    paddingLeft: "0 !important",
  },
  paddingZero: {
    padding: "0 !important",
  },
  mobilePanelMargin: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: `calc(100% + -${theme.spacing(1)})`,
  },
  visuallyHidden: {
    fontSize: 0,
    width: "1px",
    height: "1px",
    display: "inline-block",
    overflow: "hidden",
    position: "absolute",
    border: "0!important",
    padding: "0!important",
    margin: "0!important",
    clip: "rect(1px,1px,1px,1px)",
  },
  category: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      padding: theme.spacing(1),
      "& a": {
        marginLeft: 0,
        paddingRight: theme.spacing(1),
      },
    },
  },
  categoryList: {
    a: {
      color: "#555555",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginLeft: `-${theme.spacing(3.5)}`,
      width: `calc(100% + ${theme.spacing(3.5)})`,
    },
  },
  shopContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: 0,
    },
  },
}));
