import React from "react";
import { Grid, GridSize, makeStyles, Theme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    marginBottom: theme.spacing(),
  },
  rect: {
    paddingBottom: "66.6%",
    display: "block",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(),
  },
}));

export function SkeletonCard({ xs, md }: { xs?: GridSize; md?: GridSize }) {
  const classes = useStyles();
  return (
    <Grid item xs={xs || 12} md={md || 3}>
      <Grid item xs={12}>
        <Skeleton variant="rect" className={classes.rect} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" className={classes.text} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" className={classes.text} />
      </Grid>
    </Grid>
  );
}

export function SkeletonCardGridFourWide({ amount }: { amount: number }) {
  return (
    <Grid container spacing={4}>
      {[...Array(amount).keys()].map((index) => {
        return <SkeletonCard key={`${amount}--grid-four-${index}`} />;
      })}
    </Grid>
  );
}

export function SkeletonCardGridThreeWide({ amount }: { amount: number }) {
  return (
    <Grid container spacing={4}>
      {[...Array(amount).keys()].map((index) => {
        return <SkeletonCard md={4} key={`${amount}--grid-three-${index}`} />;
      })}
    </Grid>
  );
}
