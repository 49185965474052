import { createTheme } from "@material-ui/core/styles";
import { deskStart, tabletEnd, tabletStart } from "./consts";
import { sizing, spacing } from "./sizing";

const mediaQuery: string = `@media screen and (min-width: ${sizing(
  tabletEnd
)})`;

const globalTheme = createTheme({
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#7B7B7B",
      light: "#E9E9E9",
    },
    background: {
      default: "#ffffff",
    },
    error: { main: "#a22e3e" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: tabletStart,
      md: deskStart,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    h1: {
      fontSize: sizing(48),
      fontWeight: 600,
      lineHeight: "52px",
      letterSpacing: "-0.5px",
      textDecoration: "none",
      [mediaQuery]: {
        fontSize: sizing(59),
        lineHeight: "72px",
      },
    },
    h2: {
      fontSize: sizing(34),
      fontWeight: 400,
      lineHeight: "48px",
      letterSpacing: 0,
      textDecoration: "none",
      [mediaQuery]: {
        fontSize: sizing(34),
        lineHeight: "48px",
      },
    },
    h3: {
      fontSize: sizing(26),
      fontWeight: 400,
      lineHeight: "36px",
      letterSpacing: "0.25px",
      textDecoration: "none",
      [mediaQuery]: {
        fontSize: sizing(26),
        lineHeight: "36px",
      },
    },
    h4: {
      fontSize: sizing(20),
      fontWeight: 400,
      lineHeight: "30px",
      letterSpacing: 0,
      textDecoration: "none",
      [mediaQuery]: {
        fontSize: sizing(20),
        lineHeight: "30px",
      },
    },
    h5: {
      fontSize: sizing(20),
      fontWeight: 600,
      lineHeight: "32px",
      letterSpacing: "0.25px",
      textDecoration: "none",
      [mediaQuery]: {
        fontSize: sizing(20),
        lineHeight: "32px",
      },
    },
    subtitle1: {
      fontSize: sizing(16),
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "0.15px",
      textDecoration: "none",
    },
    subtitle2: {
      fontSize: sizing(14),
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.1px",
      textDecoration: "none",
    },
    body1: {
      fontSize: sizing(16),
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: 0,
      textDecoration: "none",
    },
    body2: {
      fontSize: sizing(14),
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.1px",
      textDecoration: "none",
    },
    button: {
      fontSize: sizing(14),
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      textTransform: "none",
      textDecoration: "none",
    },
    caption: {
      fontSize: sizing(12),
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      textDecoration: "none",
    },
    overline: {
      fontSize: sizing(12),
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      textDecoration: "none",
    },
    // leadBody: {
    //   fontSize: sizing(18),
    //   fontWeight: 600,
    //   lineHeight: "28px",
    //   letterSpacing: "0px",
    // },
  },
  spacing: (factor) => spacing(factor),
});

const theme = createTheme(
  {
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "'Open Sans', sans-serif",
            },
          ],
          "#root": {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          },
          input: {
            fontSize: "1rem",
          },
          main: {
            flex: 1,
          },
          a: {
            color: globalTheme.palette.grey[800],
            textDecoration: "underline",
            transition: "color 0.4s ease",
            "&:hover, &:focus": {
              color: globalTheme.palette.grey[900],
            },
          },
        },
      },
      MuiContainer: {
        root: {
          [globalTheme.breakpoints.up(globalTheme.breakpoints.values.sm)]: {
            paddingLeft: `${spacing(8)} !important`,
            paddingRight: `${spacing(8)} !important`,
          },
          [globalTheme.breakpoints.up(globalTheme.breakpoints.values.lg)]: {
            paddingLeft: `${spacing(4)} !important`,
            paddingRight: `${spacing(4)} !important`,
          },
        },
      },
      MuiTypography: {
        gutterBottom: {
          marginBottom: globalTheme.spacing(2),
        },
      },
    },
  },
  globalTheme
);

export default theme;
